<template>
  <div class="chargeoff-con">
    <NavBar
      title="核销"
      left-arrow
      @click-left="goBack"
      v-if="isWeixin_status == false"
    />
    <div class="scan-con">
      <div class="scan-time">
        <span>订单号: {{detail_info.order_sn}}</span>
        <span>消耗积分: {{detail_info.pre_credit}}</span>
      </div>
      <div class="scan-title">
        <span>核销码</span>
        <span>{{detail_info.cancel_code}}</span>
      </div>
      <div class="scan-img">
        <img v-lazy="qr_img" alt="">
      </div>
      <p>请将该二维码展示给商家核销</p>
    </div>
  </div>
</template>
<script>
import CreditShop from "@/api/creditshop";
import { NavBar } from 'vant';
import QRCode from 'qrcode';
export default {
  components: {
    NavBar
  },
  data() {
    return {
      detail_info: {},
      qr_img: ""
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
    }
  },
  methods: {
    async getDetail(idx) {
      try {
        const res = await CreditShop.getExChangeDetail(idx);
        if (res.code == 200) {
          this.detail_info = res.data;
          if (res.data.cancel_code) {
            await this.generateQR(res.data.cancel_code)
          }
        } else {
          throw(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async generateQR(url) {
      try {
        this.qr_img = await QRCode.toDataURL(url)
      } catch (err) {
        this.$toast('生成二维码失败');
        console.error(err)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.chargeoff-con {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-color: rgba(244,244,244,1);
  .scan-con {
    width: 100%;
    height: 60vh;
    background: #ffffff;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000000;
    .scan-time {
      width: 100%;
      height: 30px;
      display: flex;
      font-size: 14px;
      border-bottom: 1px solid #dfdfdf;
      justify-content: space-between;
    }
    .scan-title {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      margin-top: 10px;
      span:nth-child(2) {
        color: #000000;
        font-size: 20px;
      }
    }
    .scan-img {
      width: 250px;
      height: 250px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      margin: 10px;
      border: 1px solid #dfdfdf;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>